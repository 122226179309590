code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap");

:root {
  --gradient-linear-gray-1: linear-gradient(
    0deg,
    var(--gray-5) 5%,
    var(--gray-1) 95%
  );
  --blue-50: rgba(72, 128, 238, 1);
  --blue-60: rgba(21, 88, 221, 1);
  --blue-80: rgba(17, 61, 148, 1);
  --gray-1: rgba(245, 245, 245, 1);
  --gray-2: rgba(240, 240, 240, 1);
  --gray-5: rgba(232, 232, 232, 1);
  --gray-10: rgba(222, 222, 222, 1);
  --gray-20: rgba(198, 198, 198, 1);
  --gray-30: rgba(174, 174, 174, 1);
  --gray-40: rgba(151, 151, 151, 1);
  --gray-50: rgba(127, 127, 127, 1);
  --gray-70: rgba(80, 80, 80, 1);
  --gray-80: rgba(57, 56, 57, 1);
  --gray-90: rgba(34, 33, 34, 1);
  --gray-100: rgba(9, 9, 10, 1);
  --gray-100-alpha-35: rgba(9, 9, 10, 0.35);
  --pink-90: rgba(104, 3, 151, 1);
  --purple-20: rgba(211, 197, 255, 1);
  --purple-30: rgba(192, 173, 250, 1);
  --purple-70: rgba(63, 38, 143, 1);
  --purple-80: rgba(47, 24, 123, 1);
  --purple-90: rgba(40, 22, 98, 1);
  --purple-100: rgba(27, 7, 70, 1);
  --red-60: rgba(230, 15, 15, 1);
  --red-5: rgba(255, 194, 194, 1);
  --green-60: rgba(5, 114, 55, 1);
  --linear-1: linear-gradient(
    180deg,
    rgb(134.12, 13.77, 190.93) 0%,
    rgb(63, 38, 143) 100%
  );
  --button-font-family: "Open Sans", Helvetica;
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-font-weight: 600;
  --button-letter-spacing: 0px;
  --button-line-height: 22px;
  --small-font-family: "Open Sans", Helvetica;
  --small-font-size: 14px;
  --small-font-style: normal;
  --small-font-weight: 400;
  --small-line-height: 16px;
  --body-large-font-family: "Droid Sans", Helvetica;
  --body-large-font-size: 20px;
  --body-large-font-style: normal;
  --body-large-font-weight: 400;
  --body-large-letter-spacing: 0px;
  --body-large-line-height: 28px;
  --body-default-font: 16px/24px "Droid Sans", Helvetica;
  --body-small-font: 14px/18px "Droid Sans", Helvetica;
  --body-default-font-size: 16px;
  --body-default-font-style: normal;
  --body-default-font-weight: 400;
  --body-default-letter-spacing: 0px;
  --body-default-line-height: 24px;
  --headings-font-family: "Montserrat", Helvetica;
  --headings-margin: 10px;
  --headings-h1-font-size: 40px;
  --headings-h1-font-style: normal;
  --headings-h1-font-weight: 600;
  --headings-h1-letter-spacing: 0px;
  --headings-h1-line-height: 57px;
  --headings-h3-font-size: 34px;
  --headings-h3-font-style: normal;
  --headings-h3-font-weight: 600;
  --headings-h3-letter-spacing: 0px;
  --headings-h3-line-height: normal;
  --headings-h4-font-size: 28px;
  --headings-h6-font-family: "Montserrat", Helvetica;
  --headings-h6-font-size: 1em;
  --headings-h6-font-style: normal;
  --headings-h6-font-weight: 700;
  --headings-h6-letter-spacing: 0px;
  --headings-h6-line-height: normal;
  --button-font-family: "Open Sans", Helvetica;
  --shadow: 0px 4px 4px #000040;
  --shadow-small: 2px 2px 2px #00000040;

  /* fonts */
  --headings-h4: Montserrat;
  /* font sizes */
}

body {
  margin: 0;
  line-height: 1.28em;
  background-color: var(--gray-2);
  font: var(--body-default-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headings-font-family);
  margin-block-start: var(--headings-margin);
  margin-block-end: var(--headings-margin);
  display: flex;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

h5,
h6 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

h1 {
  font-size: var(--headings-h1-font-size);
  line-height: var(--headings-h1-line-height);
}

h3 {
  font-size: var(--headings-h3-font-size);
}

h4 {
  font-size: var(--headings-h4-font-size);
}

h6 {
  font-size: var(--headings-h6-font-size);
  text-transform: uppercase;
  font-weight: var(--headings-h6-font-weight);
}

p {
  margin: 0px;
}

small {
  font: var(--body-small-font);
}

.status-green {
  background-color: var(--green-60);
}

.top {
  align-self: top;
}

.container {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-top: 100px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
}

.main {
  padding: 40px 60px 40px 280px;
  flex-grow: 1;
}

.view {
  padding: 20px 0px;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  position: relative;
}

.view .columns {
  display: flex;
  flex-direction: row;
  gap: 20px 40px;
}

.modal form {
  max-width: 480px;
}

.view form.columns {
  gap: 10px;
  align-items: center;
}

.view .column.top {
  align-self: flex-start;
}

#filter-set .drop-down,
#filter-set select option,
#filter-set .field {
  align-items: flex-start;
  font-size: var(--small-font-size);
}

#filters form {
  display: flex;
  width: inherit;
  align-items: center;
}

#filters .grid,
#filters .columns {
  align-items: center;
  gap: 4px;
}

#filter-set .grid .columns {
  padding: 4px;
}

#filter-set h6 {
  display: block;
}

#filters .button-group {
  width: unset;
}

.view form.rows {
  gap: 20px;
  align-items: flex-end;
}

.view .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 40px;
}

.view .rows {
  gap: 30px;
}

.view form .rows {
  gap: 10px;
}

.panel {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.center {
  text-align: center;
}

.rows {
  display: flex;
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.MuiTablePagination-actions button {
  max-width: 32px;
  max-height: 32px;
}

.toggle-heading h4 a {
  text-decoration: underline;
}

.section {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  max-width: 978px;
  gap: 40px;
  margin: auto;
}

.section-header .rows {
  gap: 10px;
}

.view .section-header {
  gap: 20px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dark .form-container,
.dark .form-container a,
.dark.footer a {
  color: var(--gray-2);
}

.logo-vertical {
  align-self: stretch;
  background-image: url(./images/logo-vertical-light.svg);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100px;
}

.form-container {
  width: min-content;
  flex-direction: column;
  align-items: flex-start;
  background: var(--linear-1);
  border: 1px solid;
  border-color: var(--purple-30);
  border-radius: 45px;
  display: flex;
  flex: 0 0 auto;
  gap: 1em;
  justify-content: center;
  padding: 30px 40px 40px;
  position: relative;
  width: 25%;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  position: relative;
}

.form-container h3,
.form-container h6 {
  float: left;
}

.dark {
  background-color: var(--purple-90);
}

.full-width {
  width: -webkit-fill-available;
}

.dark .form-container input[type="text"],
.dark .form-container input[type="password"] {
  background-color: var(--gray-2);
  border: 1px solid;
  border-radius: 95px;
  box-shadow: 0px 3px 12px #0000000d;
  flex: 1;
  flex-grow: 1;
  line-height: 24px;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
}

.heading-1,
.heading-1 a {
  font-size: var(--headings-h4-size);
  font-weight: 600;
  font-family: var(--headings-h4);
  color: var(--gray-100);
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}

.btn-sm {
  border-radius: 100px;
  background: var(--Blue-60, #1558dd);
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
  color: var(--Gray-1, #fdfcfc);
  text-align: center;
  /* Button */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 137.5% */
  border: none;
  padding: 10px 12px !important;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

#filters .btn-sm {
  margin-left: 20px;
}

.step {
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.message {
  align-items: flex-start;
  background-color: var(--gray-1);
  border-radius: 22px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  overflow: hidden;
  padding: 40px;
  position: relative;
}

.step-1 {
  background: url(images/1.png);
}

.step-2 {
  background: url(images/2.png);
}

.step-3 {
  background: url(images/3.png);
}

.step {
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 58px;
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.nah .full-width {
  align-items: flex-end;
}

.nah .full-width button {
  float: right;
}

.toggle-heading {
  flex-direction: row;
  display: flex;
  align-items: baseline;
}

.card {
  width: 220px;
  height: 220px;
  overflow: hidden;
  border-radius: 16px;
  background-color: var(--gray-1);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}

.card.empty-state-help {
  width: 440px;
  height: max-content;
}

.card.empty-state-help .card-content {
  padding: 20px 30px;
}

.card-content {
  padding: 10px 20px;
  display: flexbox;
  overflow-y: auto;
}

.card-content.rows {
  gap: 10px;
}

.card-img {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-block: 10px;
  background-color: var(--gray-80);
}

.min-content {
  width: min-content;
}

/* info group */

.info-groups {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.info-groups.columns {
  gap: 80px;
}

.info-group {
  gap: 4px;
}

.info-group p {
  margin-block-start: 10px;
}

.info-group .columns,
.info-group form.columns {
  gap: 4px;
  align-items: center;
}

.info-group.rows {
  gap: 10px;
}

.info-group .columns input {
  text-align: right;
}

/* responsive stuff */

@media (max-width: 900px) {
  .container {
    padding: 5px;
  }

  .container .section {
    flex-direction: column-reverse;
  }

  .form-container {
    width: -webkit-fill-available;
  }

  .main {
    padding: 80px 10px;
    height: 100vh;
  }

  h2 {
    justify-content: center;
  }

  .view .grid {
    gap: 20px 20px;
    justify-content: space-evenly;
    padding-block-end: 80px;
  }

  .card {
    height: 240px;
  }
}

.footer {
  color: var(--gray-10);
  position: fixed;
  bottom: 0;
  padding: 10px;
}

.drop-down,
/* select option {
  min-width: 40px;
  padding: 6px 4px;
  border-radius: 12px;
  font: var(--body-default-font);
} */

.manage-subscription {
  min-width: 680px;
}

.hidden {
  display: none;
}

.MuiDataGrid-main {
  order: 4;
}

.MuiDataGrid-root {
  background-color: var(--gray-1);
  border-radius: 16px !important;
}

.MuiDataGrid-footerContainer {
  background-color: var(--gradient-linear-gray-1);
  border: none;
  border-radius: 16px;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: var(--gray-1) !important;
}

.data-grid-container {
  flex: 1;
  width: 100%;
  overflow: auto;
  position: absolute;
  padding-block-end: 120px;
}

.overlay {
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.overlay .MuiAlert-colorInfo {
  padding: 10px 30px !important;
  font: var(--body-default-font) !important;
  background-color: var(--purple-20) !important;
  border-radius: 14px;
}

.overlay .MuiAlert-icon {
  color: var(--purple-80) !important;
}
