.dark .form-container form {
  gap: 20px;
}

.content .logo-vertical {
  align-self: stretch;
  background-image: url(../images/logoverticallight-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 95.56px;
  position: relative;
  width: 100%;
}

.title-text {
  white-space: pre;
}
